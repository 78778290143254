<template>
    <v-container fluid style="padding-bottom: 100px;">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
            <v-col cols="12" class="mt-3">
                <v-container fluid>
                    <v-row>
                        <v-col xs="12" sm="9" md="9" class="col-12">
                            <v-card outlined height="400">
                                <v-card-title class="lighten-5 p-2">
                                    <p>* Field are required. Please fill your fields for Generate</p>
                                </v-card-title>
                                <v-card-text class="p-3">
                                    <v-container fluid>
                                        <v-row>
                                            <v-col xs="12" sm="6" md="4" class="col-6">
                                                <h6 class="blue-lcd" style="font-weight:600">Mill <span class="red--text"><strong>* </strong></span></h6>
                                                <v-autocomplete
                                                    solo
                                                    clearable
                                                    v-model="mill"
                                                    :items="mills"
                                                    item-value="mill_id"
                                                    :item-text="item => item.mill_id +' - '+ item.mill_name.trim()"
                                                    class="border-12"
                                                    hide-details=true
                                                    @change="(event) => getMachType(event)"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col xs="6" sm="6" md="4" class="col-6">
                                                <h6 class="blue-lcd" style="font-weight:600">Machine Type <span class="red--text"><strong>* </strong></span></h6>
                                                <v-autocomplete
                                                    solo
                                                    clearable
                                                    v-model="mach_type"
                                                    :items="mach_types"
                                                    item-value="mach_type"
                                                    :item-text="item => item.mach_type +' - '+ item.descr.trim()"
                                                    class="border-12"
                                                    hide-details=true
                                                    @change="(event) => [getMachID(event), getMaintenanceSection(event)]"
                                                    :disabled="disabled_form"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col xs="6" sm="6" md="4" class="col-6">
                                                <h6 class="blue-lcd" style="font-weight:600">Machine ID <span class="red--text"><strong>* </strong></span></h6>
                                                <v-autocomplete
                                                    solo
                                                    clearable
                                                    v-model="mach_id"
                                                    :items="mach_ids"
                                                    item-value="mach_id"
                                                    :item-text="item => item.mach_id +' - '+ item.descr.trim()"
                                                    class="border-12"
                                                    hide-details=true
                                                    :disabled="disabled_form"
                                                    @change="(event) => [getMaintenanceSection(event)]"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col xs="6" sm="6" md="4" class="col-6">
                                                <h6 class="blue-lcd" style="font-weight:600">Maint. Section <span class="red--text"><strong>* </strong></span></h6>
                                                <v-autocomplete
                                                    solo
                                                    clearable
                                                    v-model="main_section"
                                                    :items="main_sections"
                                                    item-value="section_id"
                                                    :item-text="item => item.section_id +' - '+ item.descr.trim()"
                                                    class="border-12"
                                                    hide-details=true
                                                    :disabled="disable_sectid"
                                                    :loading="isLoadingMainSection"
                                                    @change="(event) => [getPartMachine(event), getMasterMaintenance(event)]"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col xs="6" sm="6" md="4" class="col-6">
                                                <h6 class="blue-lcd" style="font-weight:600">Part List <span class="red--text"><strong>* </strong></span></h6>
                                                <v-autocomplete
                                                    solo
                                                    clearable
                                                    v-model="part_mach"
                                                    :items="part_machs"
                                                    item-value="part_id"
                                                    :item-text="item => item.part_id +' - '+ item.descr.trim()"
                                                    class="border-12"
                                                    hide-details=true
                                                    return-object
                                                    @change="(event) => [getPeriode(event), getMasterMaintenance(event)]"
                                                    :disabled="disabled_form"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col xs="6" sm="6" md="4" class="col-6">
                                                <h6 class="blue-lcd" style="font-weight:600">Periode <strong style="color:red;">*</strong><span></span></h6>
                                                <v-autocomplete
                                                    solo
                                                    clearable
                                                    v-model="periode"
                                                    :items="periodes"
                                                    item-value="value"
                                                    :item-text="item => item.text"
                                                    class="border-12"
                                                    hide-details=true
                                                    :disabled="true"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col xs="12" sm="12" md="3" class="col-12">
                                                <v-btn block class="mt-7 border-12 mr-2" style="padding: 24px;" elevation="2" @click="generateQR()"><v-icon class="mr-1">mdi-barcode-scan</v-icon>Generate QR Code</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col xs="12" sm="3" md="3" class="col-12">
                            <v-card outlined height="400">
                                <v-card-title class="lighten-5 p-2">
                                    Result
                                    <v-spacer></v-spacer>
                                    <v-btn
                                    small
                                    class="py-5 "
                                    @click="printTicket()"
                                    >
                                        <v-icon>mdi-printer</v-icon> Print
                                    </v-btn>
                                </v-card-title>
                                <v-card-text >
                                    <div id="divPrintQrCode" style="border: 1px solid rgb(139 131 131 / 87%); padding: 15px;">
                                        <v-row align="center" justify="center" class="mt-3">
                                            <v-col cols="12">
                                                <div id="qrcode-2" style="width: 100%;height: 200px;text-align: center;display: flex;justify-content: center;align-items: center;"></div>
                                            </v-col>
                                        </v-row>
                                        <v-row align="center" justify="center" class="mt-5">
                                            <v-col cols="12">
                                                <h6 class="text-center mt-4" style="font-size: 14px; text-align: center;">
                                                    Scan kode QR dengan Web Viva
                                                </h6>
                                            </v-col>
                                        </v-row>
                                    </div>                                    
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {filter} from "@/backend-api/filter/index"
import {backendApi} from "@/backend-api/backend-api-sr"
import { Money } from 'v-money'

export default {
    components: {
        Money
    },
    data() {
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            money: {
				decimal: '.',
				thousands: ',',
				prefix: '',
				suffix: '',
				precision: 0,
				masked: false
			},
            timeout: 7500,
            search:'',
            breadcumbs: [
                {
                    text: 'Sunrise Steel',
                    disabled: false,
                    href: '/admin/sr',
                },
                {
                    text: 'Maintenance',
                    disabled: true,
                },
                {
                    text: 'Generate QR',
                    disabled: true,
                }
            ],
            mills:[],
            mill: 'SR        ',
            mach_type: '',
            mach_types: [],
            mach_id: '',
            mach_ids: [],
            main_section: '',
            main_sections: [],
            part_mach: '',
            part_machs: [],
            mst_maintenance: '',
            mst_maintenances: [],
            periodes: [
                {
                    text: 'DAY',
                    value: 'DAY'
                },
                {
                    text: 'SHIFT',
                    value: 'SHIFT'
                },
                {
                    text: 'TIME',
                    value: 'TIME'
                }
            ],
            periode: '',
            modal_request: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            shifts: [
                {
                    text: '1',
                    value: 1
                },
                {
                    text: '2',
                    value: 2
                },
                {
                    text: '3',
                    value: 3
                }
            ],
            shift: '',
            disabled_shift: false,
            disable_sectid: false,
            details: [],
            disabled_form: false,
            headerSet: false,
            headers:[
                // { text: 'Trans Date', value: 'trans_date',width:'200' , align:'left', groupable: false},
                // { text: 'Check List', value: 'descr' , align:'left', groupable: false},
                { text: 'Check Descr', value: 'check_descr' , align:'left', class: 'my-header-style'},
                { text: 'Shift', value: 'shift_id',width:'100' , align:'left', groupable: false},
                { text: 'Seq No', value: 'seq_no',width:'100' , align:'left', groupable: false},
                { text: 'Min', value: 'min',width:'100' , align:'right', groupable: false},
                { text: 'Max', value: 'max',width:'100' , align:'right', groupable: false},
                { text: 'Result', value: 'check_result',width:'250' , align:'left', groupable: false}
            ],
            tr_id: '',
            remark: '',
            number_of_seqs: [],
            seq_no: '',
            dialog: false,
            envExist: false,
            isLoadingMainSection: false,
            alert: false,
            isMobile: false,
            dialog_scan: false,
            result_scan: '',
            qrcode: ''
        }
    },
    computed:{
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getMill()
        await this.getMachType('SR')
        await this.getMachID('SR')
        await this.getEnvConf()
        this.$store.dispatch('setOverlay', false)
    },
    methods: {
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        filterSeqNo (value, search, item) {
            // console.log(value, item, search);
            return value != null &&
            search != null && item.seq_number == search
        },
        isMobileFunc() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        clear(){
            this.headerSet = false
            // this.mach_id = ''
            // this.main_section = ''
            this.part_mach = ''
            this.periode = ''
            this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.mst_maintenances = []
            this.disabled_form = false
            if(!this.envExist){
                this.disable_sectid = false
            }
            this.remark = ''
            this.tr_id = ''
        },
        onDecode(text) {
            this.result_scan = text
            console.log(`Decode text from QR code is ${text}`)
        },
        onLoaded() {
            console.log(`Ready to start scanning barcodes`)
        },
        async getEnvConf(){
            const respVarSectID = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=SR&appl_id=WEBSR&var_id=MAINTSECT&groupid=${this.$store.state.sr.group_id}`, null, false, false, false) 

            if (respVarSectID.data.data != null) {
                var arrayrespVarSectID = respVarSectID.data.data.split(",");

                if (arrayrespVarSectID.length > 1) {
                    var arrayReusable = []
                    for (let index = 0; index < arrayrespVarSectID.length; index++) {
                        const element = arrayrespVarSectID[index];
                        const arrayIndex = this.main_sections.findIndex(x => x.section_id === element);
                        arrayReusable.push(this.main_sections[arrayIndex]);
                    }

                    this.main_sections = arrayReusable
                } else {
                    this.main_section = respVarSectID.data.data
                    this.disable_sectid = true
                    this.envExist = true
                    await this.getPartMachine(this.main_section)
                }
                
            } else {
                this.main_section = ''
                this.disable_sectid = false
            }
        },
        async getMill(){
            const respData = await backendApi.fetchCore(`/v2/sr/maintenance/mill?mill_id=${this.mill ? this.mill.trim() : ''}`, null, false, false, false) 
            if (respData.status === 200) {
                this.mills = respData.data.data
            }
        },
        async getMachType(event){
            if (event === '' || event === null) {
                this.mach_type = ''
            } else {
                const respData = await backendApi.fetchCore(`/v2/sr/maintenance/mach-type?mill_id=${this.mill ? this.mill.trim() : ''}`, null, false, false, false) 
                if (respData.status === 200) {
                    this.mach_types = respData.data.data
                }
            }
        },
        async getMachID(event){
            if (event === '' || event === null) {
                this.mach_id = ''
            } else {
                const respData = await backendApi.fetchCore(`/v2/sr/maintenance/mach-id?mill_id=${this.mill ? this.mill.trim() : ''}&mach_type=${this.mach_type ? this.mach_type : ''}`, null, false, false, false) 
                if (respData.status === 200) {
                    this.mach_ids = respData.data.data
                }
            }
        },
        async getMaintenanceSection(event){
            if (event === '' || event === null) {
                this.main_section = ''
            } else {
                const respData = await backendApi.fetchCore(`/v2/sr/maintenance/maint-section?mill_id=${this.mill ? this.mill.trim() : ''}&mach_type=${this.mach_type ? this.mach_type : ''}&mach_id=${this.mach_id ? this.mach_id : ''}`, null, false, false, false) 
                if (respData.status === 200) {
                    this.main_sections = respData.data.data
                }
            }
        },
        async getPartMachine(event){
            if (event === '' || event === null) {
                this.part_mach = ''
            } else {
                this.isLoadingMainSection = true
                this.part_machs = []
                const respData = await backendApi.fetchCore(`/v2/sr/maintenance/part-of-mach?mill_id=${this.mill ? this.mill.trim() : ''}&section_id=${this.main_section ? this.main_section : ''}`, null, false, false, false) 
                if (respData.status === 200) {
                    this.part_machs = respData.data.data
                    this.isLoadingMainSection = false
                }
            }
        },
        getPeriode(event){
            console.log('getPeriode')
            console.log(event)
            console.log(this.periode)
            if (event === '' || event === null) {
                this.periode = ''
            } else {
                this.periode = event.period.trim() ? event.period : ''
            }
            console.log(this.periode)
        },
        async getMasterMaintenance(event){
            if (event === '' || event === null) {
                this.mst_maintenance = ''
            } else {
                const respData = await backendApi.fetchCore(`/v2/sr/maintenance/master-maintenance?mill_id=${this.mill ? this.mill.trim() : ''}&section_id=${this.main_section ? this.main_section.trim() : ''}&part_id=${this.part_mach ? this.part_mach.part_id.trim() : ''}`, null, false, false, false) 
                if (respData.status === 200) {
                    this.mst_maintenances = respData.data
                }
            }
        },
        generateQR(){

            this.$store.dispatch('setOverlay', true)

            if (this.mill == '' || this.mach_type === '' || this.mach_id === '' || this.main_section === '' || this.part_mach === '' || this.periode === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please fill your fields for generate',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)

                return false
            }
            
            let data = {
                mill_id: this.mill,
                mach_type: this.mach_type,
                mach_id: this.mach_id,
                section_id: this.main_section,
                part_id: this.part_mach ? this.part_mach.part_id.trim() : '',
                periode: this.periode,
                // check_id: this.mst_maintenance
            }

            let stringdata = JSON.stringify(data)
            if (this.qrcode) {
                this.qrcode.clear();
                this.qrcode.makeCode(stringdata);
            } else {
                this.qrcode = new QRCode(document.getElementById("qrcode-2"), {
                                text: stringdata,
                                width: 220,
                                height: 220,
                                correctLevel : QRCode.CorrectLevel.H
                            });
            }
            

            this.$store.dispatch('setOverlay', false)

        },
        clear(){
            if (this.qrcode) {
                this.qrcode.clear();
            }
        },
        printTicket(){
            const elem = document.getElementById("divPrintQrCode")
            var domClone = elem.cloneNode(true);
    
            var $PrintQrCode = document.getElementById("PrintQrCode");
            
            if (!$PrintQrCode) {
                var $PrintQrCode = document.createElement("div");
                $PrintQrCode.id = "PrintQrCode";
                document.body.appendChild($PrintQrCode);
            }
            
            $PrintQrCode.innerHTML = "";
            $PrintQrCode.appendChild(domClone);
            window.print();
        }
    },
    watch: {
    }    
}
</script>
<style scoped>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .9;
    position: absolute;
    width: 100%;
}
.tr_datatable{
  background-color: #F5F7F8 !important;
}

.v-card__text {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
.v-card {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}


</style>